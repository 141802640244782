<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ user.name }} <v-spacer /> <span class="text-button pr-1">{{ user.id }} </span>
        </v-card-title>

        <v-card-text>
          <div class="mt-2">
            <span class="font-weight-bold">Registered:</span> {{ dayjs(user.createdAt).format('DD.MM.YYYY HH:mm:ss') }}
          </div>
          <div><span class="font-weight-bold">T&C agreed:</span> {{ user.agreedToTC }}</div>
          <div><span class="font-weight-bold">Email:</span> {{ user.email }}</div>
          <div><span class="font-weight-bold">Active:</span> {{ user.active }}</div>
          <div><span class="font-weight-bold">LandlordID:</span> {{ user.landlordId || 'N/A' }}</div>
          <div><span class="font-weight-bold">Geofence Enabled:</span> {{ user.geofenceEnabled || 'false' }}</div>
          <div><span class="font-weight-bold">Current Location:</span> {{ user.whereAmI || 'N/A' }}</div>
          <div><span class="font-weight-bold">Push Alarm Change:</span> {{ user.pushWhenAlarmChange || 'N/A' }}</div>
          <div><span class="font-weight-bold">Push Scene Change:</span> {{ user.pushWhenSceneChange || 'N/A' }}</div>
          <div><span class="font-weight-bold">Push Alarm Triggered:</span> {{ user.pushNotificationWhenAlarm || 'N/A' }}</div>
          <div><span class="font-weight-bold">Notification window/door left open:</span> {{ user.openSensorsFoundByAutomation || 'N/A' }}</div>
          <div><span class="font-weight-bold">Deals And Offers:</span> {{ user.subscribeDealsOffers || 'N/A' }}</div>
          <div><span class="font-weight-bold">Newsletter:</span> {{ user.subscribeNewsletter || 'N/A' }}</div>
          <div><span class="font-weight-bold">Permissions:</span></div>
          <xmp v-if="dialog">
            {{ checkPermissions(user.propertyLevelPermissions) }}
          </xmp>
          <!-- {{ user }} -->
          <div class="mt-3 text-caption">
            Tenant and Homeowner - permissions are set by nature of the roles as highest (except. tenant cannot remove home and devices belonging to landlord)
          </div>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="$emit('close')"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  props: {
    dialog: { type: Boolean, default: false },
    selectedPropertyId: { type: Number, default: 0 },
    user: { type: Object, default: () => {} }
  },
  emits: ['close'],
  data: () => ({
    dayjs
  }),
  computed: {
  },
  created () {
  },
  methods: {
    checkPermissions (permissionSet) {
      return permissionSet.find(item => parseInt(item.propertyId) === parseInt(this.selectedPropertyId))
    }
  }
}
</script>
