<template>
  <div>
    <v-progress-linear
      v-if="pullingData"
      indeterminate
      color="blue"
    />
    <v-slide-x-transition mode="out-in">
      <v-container
        v-if="!pullingData"
        class="pl-10 pr-10"
        fluid
      >
        <div class="top-info-part">
          <v-card elevation="0" rounded="xl">
            <v-card-title>
              {{ mainUser.name }}
              <v-spacer />
              <v-icon size="small" color="green">
                mdi-information-outline
              </v-icon>
              <span class="text-caption">
                {{ mainUser.id }}
              </span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  v-for="(property, i) in lodash.orderBy(userProperties, ['id'], ['asc'])"
                  :key="i"
                  sm="12"
                  cols="12"
                  md="4"
                  xl="3"
                >
                  <v-card
                    elevation="0"
                    rounded="lg"
                    class="pointer"
                    :class="parseInt(selectedProperty.id) === parseInt(property.id) ? 'active-class-home-selected' : ''"
                    :color="checkRole(property.id) === 'missing' ? 'red lighten-4' : '#eff4fa'"
                    @click="selectProperty(property)"
                  >
                    <v-card-title class="text-body-2">
                      {{ property.propertyName }}
                      <v-tooltip v-if="i === 0" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="green"
                            size="medium"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-star
                          </v-icon>
                        </template>
                        <span>Users Primary Property</span>
                      </v-tooltip>
                      <v-spacer />
                      <v-icon size="small" color="green">
                        mdi-information-outline
                      </v-icon>
                      <div class="text-caption">
                        {{ property.id }}
                      </div>
                    </v-card-title>
                    <v-card-text class="text-caption">
                      <v-row>
                        <v-col>
                          {{ property.street }}<br>
                          {{ property.city }}
                        </v-col>
                        <v-col class="text-right">
                          <v-tooltip v-if="property.landlordId" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="orange lighten-2"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-account-group
                              </v-icon>
                            </template>
                            <span>Landlords property user is Tenant of {{ property.landlordId }}</span>
                          </v-tooltip>
                          <v-tooltip v-if="checkRole(property.id) === 'Home Owner'" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="blue lighten-2"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-crown
                              </v-icon>
                            </template>
                            <span>User is owner of this home</span>
                          </v-tooltip>
                          <v-tooltip v-if="checkRole(property.id) === 'House Member'" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="purple lighten-2"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-account-child
                              </v-icon>
                            </template>
                            <span>User is member in this home</span>
                          </v-tooltip>
                          <v-tooltip v-if="checkRole(property.id) === 'missing'" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="red"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-alert-circle
                              </v-icon>
                            </template>
                            <span>There is an issue with this property (check with Ondrej > property ID: {{ property.id }})</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            <!-- {{ userPropertyExtras }} -->
            </v-card-text>
          </v-card>
        </div>
        <div class="main-info-part">
          <v-card
            v-if="selectedProperty"
            elevation="0"
            rounded="xl"
          >
            <v-card-title>
              <v-icon
                color="purple lighten-2"
              >
                mdi-home
              </v-icon>
              <span class="pl-1">{{ selectedProperty.propertyName }}</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  lg="4"
                  md="6"
                  sm="6"
                  xs="12"
                >
                  <div class="pa-1">
                    <v-card
                      style="min-height: 190px;"
                      rounded="lg"
                      elevation="0"
                      color="#eff4fa"
                    >
                      <v-card-subtitle class="text-button pb-0">
                        General
                      </v-card-subtitle>
                      <v-card-text>
                        <div><span class="font-weight-bold">Address:</span> {{ selectedProperty.street }},{{ selectedProperty.city }},{{ selectedProperty.country }}</div>
                        <v-divider class="mt-2 mb-2" />
                        <v-row>
                          <v-col>
                            <div><span class="font-weight-bold">Gateway:</span> {{ selectedProperty.propertyGateway ? selectedProperty.propertyGateway.gateway : 'N/A' }} | <span v-if="selectedProperty.gatewayActive" class="green--text">Active</span><span v-else class="red--text">Inactive</span></div>
                            <div><span class="font-weight-bold">Active Scene:</span> {{ selectedProperty.extras.sceneActiveName }} / {{ selectedProperty.extras.sceneActiveID }}</div>
                          </v-col>
                          <v-col
                            cols="2"
                            md="2"
                            sm="2"
                            xs="2"
                            class="text-right pa-0"
                          >
                            <img
                              v-if="selectedProperty.propertyGateway ? selectedProperty.propertyGateway.gateway === 'SEG-X3' : false"
                              style="max-width: 60px; margin-top: 10px; margin-right: 20px;"
                              name="gatewayX3"
                              src="@/assets/gateway.png"
                            >
                            <img
                              v-else-if="selectedProperty.propertyGateway ? selectedProperty.propertyGateway.gateway === 'SEG-X5' : false"
                              style="max-width: 60px; margin-top: 10px; margin-right: 20px;"
                              name="gatewayX5"
                              src="@/assets/gateway2.png"
                            >
                            <v-icon
                              v-else
                              color="red"
                              style="max-width: 60px; margin-top: 10px; margin-right: 20px;"
                            >
                              mdi-earth-off
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  lg="4"
                  md="6"
                  sm="6"
                  xs="12"
                >
                  <div class="pa-1">
                    <v-card
                      style="min-height: 190px;"
                      rounded="lg"
                      elevation="0"
                      color="#eff4fa"
                    >
                      <v-card-subtitle class="text-button pb-0">
                        Information
                      </v-card-subtitle>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <div><span class="font-weight-bold">Homevision Member:</span> <span :class="selectedProperty.isMember ? 'green--text' : 'red--text'">{{ selectedProperty.isMember }}</span></div>
                            <div><span class="font-weight-bold">IKEA Integration:</span> <span :class="selectedProperty.ikeaIntegrationEnabled ? 'green--text' : 'red--text'">{{ selectedProperty.ikeaIntegrationEnabled }}</span></div>
                            <div><span class="font-weight-bold">StripeCustomerId:</span> {{ selectedProperty.stripeCustomerId || 'Haven\'t shopped' }}</div>
                            <v-divider class="mt-1 mb-1" />
                            <div><span class="font-weight-bold">Water Leak Detected:</span> <span v-if="selectedProperty.waterLeak" class="red--text">!!Detected @ {{ selectedProperty.leakDetectedAt }}</span> <span v-else class="green--text">All fine</span></div>
                            <div><span class="font-weight-bold">Smoke/Fire Detected:</span> <span v-if="selectedProperty.fire" class="red--text">!!Detected @ {{ selectedProperty.fireDetectedAt }}</span> <span v-else class="green--text">All fine</span></div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  lg="4"
                  md="6"
                  sm="6"
                  xs="12"
                >
                  <div class="pa-1">
                    <v-card
                      rounded="lg"
                      elevation="0"
                      color="#eff4fa"
                      style="min-height: 190px;"
                    >
                      <v-card-subtitle class="text-button pb-0">
                        Users in {{ selectedProperty.propertyName }}
                      </v-card-subtitle>
                      <v-card-text class="pt-2">
                        <v-row
                          v-for="(user, i) in selectedProperty.extras.allPropertyUsers"
                          :key="i"
                        >
                          <v-col class="pb-1 pt-1">
                            <div class="border-bottom">
                              <span class="pointer" @click="openUser(user)">
                                <v-icon
                                  class="pl-1 pointer"
                                  size="medium"
                                  color="blue"
                                >
                                  mdi-eye
                                </v-icon>
                                <span class="text-caption">
                                  {{ user.id }}
                                </span>
                              </span> {{ user.name }} | <span class="text-caption">{{ user.email }} | <span :class="mapRoleColor(findRole(user))">{{ findRole(user) }}</span></span>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card
            v-else
            elevation="0"
            rounded="xl"
          >
            <v-card-text class="text-center">
              <div class="text-h5">
                Please select property
              </div>
            </v-card-text>
          </v-card>
          <v-card
            v-if="!pullingData"
            class="devices-info-part pb-2"
            elevation="0"
            rounded="xl"
          >
            <v-card-title>Devices</v-card-title>
            <v-card-text>
              <v-row class="font-weight-bold pb-0 border-bottom">
                <v-col class="pb-0">
                  ID/Status
                </v-col>
                <v-col class="pb-0">
                  IEEE
                </v-col>
                <v-col cols="1" class="pb-0">
                  Room Id
                </v-col>
                <v-col cols="3" class="pb-0">
                  Name/Type
                </v-col>
                <v-col class="pb-0">
                  Battery
                </v-col>
                <v-col class="pb-0">
                  LLDevice
                </v-col>
                <v-col class="pb-0">
                  Notification
                </v-col>
                <v-col class="pb-0">
                  Scene Notif
                </v-col>
              </v-row>
              <v-row
                v-for="device in userDevices.devices"
                :key="device.id"
                :style="device.active ? '' : 'background: rgba(255, 0, 0, 0.1);'"
              >
                <v-col class="pb-0 pt-0 border-bottom">
                  <div>{{ device.id }}</div>
                  <div :class="device.active !== true ? 'red--text' : 'green--text'" class="pb-0">
                    {{ device.active ? 'Connected' : 'Disconnected' }}
                  </div>
                </v-col>
                <v-col class="pb-0 border-bottom">
                  {{ device.ieee }}
                </v-col>
                <v-col cols="1" class="pb-0 border-bottom">
                  {{ device.propertyRoomId }}
                </v-col>
                <v-col cols="3" class="pb-0 pt-0 border-bottom">
                  <div class="green--text">
                    {{ device.deviceName }}
                  </div>
                  <div>{{ device.typeI }}</div>
                </v-col>
                <v-col
                  :class="device.battery === 0 ? 'green--text' : 'red--text'"
                  class="pb-0 border-bottom"
                >
                  {{ device.battery === 0 ? 'OK' : 'Low' }}
                </v-col>
                <v-col class="pb-0 border-bottom">
                  {{ device.landlordsDevice }}
                </v-col>
                <v-col class="pb-0 border-bottom">
                  {{ device.notification }}
                </v-col>
                <v-col class="pb-0 border-bottom">
                  {{ device.notificationWhenScene }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card
            v-if="!pullingData"
            class="devices-info-part pb-2"
            elevation="0"
            rounded="xl"
          >
            <v-card-title>Cameras</v-card-title>
            <v-card-text>
              <v-row class="font-weight-bold pb-0 border-bottom">
                <v-col class="pb-0">
                  ID/Status
                </v-col>
                <v-col class="pb-0">
                  Identification
                </v-col>
                <v-col class="pb-0">
                  Serial Number
                </v-col>
                <v-col class="pb-0">
                  Name/Type
                </v-col>
                <v-col class="pb-0">
                  Armed
                </v-col>
                <v-col class="pb-0">
                  Notification
                </v-col>
                <v-col class="pb-0">
                  Acoba User Id
                </v-col>
              </v-row>
              <v-row
                v-for="camera in userDevices.cameras"
                :key="camera.id"
              >
                <v-col class="pb-0 pt-0 border-bottom">
                  <div>{{ camera.id }}</div>
                  <div :class="camera.connected !== true ? 'red--text' : 'green--text'" class="pb-0">
                    {{ camera.connected ? 'Connected' : 'Disconnected' }}
                  </div>
                </v-col>
                <v-col class="pb-0 border-bottom">
                  {{ camera.acobaCameraIdentification }}
                </v-col>
                <v-col class="pb-0 border-bottom">
                  {{ camera.acobaSerialNumber }}
                </v-col>
                <v-col class="pb-0 pt-0 border-bottom">
                  <div class="green--text">
                    {{ camera.acobaCameraName }}
                  </div>
                  <div>{{ camera.acobaCameraModel }}</div>
                </v-col>
                <v-col
                  class="pb-0 border-bottom"
                >
                  {{ camera.alarmed ? 'YES' : 'NO' }}
                </v-col>
                <v-col class="pb-0 border-bottom">
                  {{ camera.notification }}
                </v-col>
                <v-col class="pb-0 border-bottom">
                  {{ 'homevision-' + camera.acobaUserId }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        <!-- {{ userDevices.cameras }} -->
        </div>
        <user-modal
          :dialog="openUserModal"
          :user="openUserModalWithUser"
          :selected-property-id="selectedPropertyId"
          @close="openUserModal = false;"
        />
      </v-container>
    </v-slide-x-transition>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import _ from 'lodash'

import userModal from '../components/UserDetailModal.vue'

export default {
  name: 'UserDetail',
  components: {
    userModal
  },
  data () {
    return {
      lodash: _,
      dayjs,
      selectedView: '1',
      selectedPropertyId: null,
      selectedProperty: null,
      viewingUserId: null,
      pullingData: true,
      userProperties: null,
      mainUser: null,
      userPropertyExtras: null,
      userDevices: { devices: [], cameras: [] },
      openUserModal: false,
      openUserModalWithUser: {}
    }
  },
  watch: {
    '$route.params.id': {
      handler: function () {
        this.viewingUserId = this.$route.params.id
        this.loadUserProperty()
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.viewingUserId = this.$route.params.id
    if (!this.viewingUserId) {
      this.viewingUserId = this.$route.params.id
    }

    this.loadUserProperty()
  },
  methods: {
    loadUserProperty () {
      this.pullingData = true
      this.$store.dispatch('getProperty', this.viewingUserId)
        .then(response => {
          this.userProperties = response.data.properties
          this.mainUser = response.data.mainUser
          this.selectedProperty = this.lodash.orderBy(response.data.properties, ['id'], ['asc'])[0]
          this.selectedPropertyId = this.selectedProperty.id
          this.loadUserDevices()
          this.pullingData = false
        })
    },
    loadUserDevices () {
      this.pullingData = true
      this.$store.dispatch('getUserDevices', { userId: this.viewingUserId, propertyId: this.selectedPropertyId }).then(response => {
        this.userDevices = response.data
        this.pullingData = false
      })
    },
    findUserDetail (userId) {
      return userId
    },
    redirectToLogs () {
      this.$router.push({ path: '/propertylog/' + this.userProperty.id })
    },
    redirectToCompactLogs () {
      this.$router.push({ path: '/propertylogcompact/' + this.userProperty.id })
    },
    checkRole (propertyId) {
      const propertyIdInt = parseInt(propertyId)
      const found = this.mainUser.propertyLevelPermissions.find(item => parseInt(item.propertyId) === propertyIdInt)

      return found ? found.role : 'missing'
    },
    findRole (user) {
      const roleObject = user.propertyLevelPermissions.find(item => parseInt(item.propertyId) === parseInt(this.selectedPropertyId))
      return roleObject ? roleObject.role : 'Not found'
    },
    mapRoleColor (role) {
      if (role === 'Home Owner') {
        return 'blue--text'
      } else if (role === 'Tenant') {
        return 'red--text'
      } else {
        return 'purple--text'
      }
    },
    selectProperty (property) {
      if (parseInt(property.id) !== parseInt(this.selectedPropertyId)) {
        this.selectedProperty = property
        this.selectedPropertyId = property.id
        this.loadUserDevices()
      }
    },
    openUser (user) {
      this.openUserModalWithUser = user
      this.openUserModal = true
    }
  }
}
</script>

<style scoped>
/* .house-selector {
    background: #eff4fa;
} */
.active-class-home-selected {
    border: 1px solid #031738!important;
}
.main-info-part {
    margin-top: 25px;
}

.devices-info-part {
    margin-top: 25px;
}
.border-bottom {
    border-bottom: 1px solid rgba(128, 128, 128, 0.3);
}
.pointer {
    cursor: pointer;
}
</style>
